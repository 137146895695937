$(document).ready(function () {

    $('#ft-type').change(function () {
        $('#funeral-hider').slideToggle();
    });

    $('.previous-step-button', '#funeral-agreementform').unbind().click(function (event) {
        event.preventDefault();
        let currentStep = parseInt($(this).parents('.steps').attr('data-step'));
        let nextStep = currentStep - 1;

        $('.steps[data-step="' + currentStep + '"]').fadeOut(function () {
            $('.current-step').text(nextStep);
            $('h4', '.form-header').text($('.steps[data-step="' + nextStep + '"]').attr('data-heading'));
            $('.steps[data-step="' + nextStep + '"]').fadeIn();
        });
    });

    $('.next-step-button', '#funeral-agreementform').unbind().click(function (event) {
        if ($(this).is('#final-step')) {
            return;
        }

        event.preventDefault();

        var validationPassed = true;
        let currentStep = parseInt($(this).parents('.steps').attr('data-step'));
        let nextStep = currentStep + 1;
        var formData = '';

        $('input, textarea', '.steps[data-step="' + currentStep + '"]').not(':input[type=submit]').each(function (k, v) {
            if (!v.checkValidity()) {
                validationPassed = false;
                v.reportValidity();
            }

            formData += $(this).serialize() + '&';
        });

        if (validationPassed === false) {
            return;
        }

        $('.loader').css({'display': 'flex'});

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        $.ajax({
            type: 'POST',
            url: '/funeral-agreement/store',
            data: formData,
            success: function (data) {
                $('.steps[data-step="' + currentStep + '"]').fadeOut(function () {
                    $('.current-step').text(parseInt($('.current-step').text()) + 1);
                    $('h4', '.form-header').text($('.steps[data-step="' + nextStep + '"]').attr('data-heading'));
                    $('.steps[data-step="' + nextStep + '"]').fadeIn();
                });

                $('.loader').hide();
            },
            error: function (data) {
                var data = data.responseJSON;

                $.each(data, function (k, v) {
                    $("input[id='" + k + "']").css({'border': '1px solid red'});
                });

                $('.loader').hide();
            }
        });

    });

    $('#coffin_preference').select2({
        templateResult: formatState,
        allowClear: true,
        placeholder: "Geen voorkeur",
    });
});

function formatState (state) {
    if (!state.id || !state.element) {
        return state.text;
    }

    var template = $('#coffin-option').html()
        .replace(/%%image%%/g, $(state.element).data('image'))
        .replace(/%%title%%/g, state.text);

    return $(template);
}