$(document).ready(function () {

    /**
     * Navigation.
     */
    $('#navigation-toggle').click(function () {

        if ($('header').hasClass('white-background')) {
            $('header').removeClass('white-background');
        } else {
            $('header').addClass('white-background');
        }

        $('nav').toggle();
    });

    if ($(window).width() < 992) {
        $('.navigation-item').unbind().click(function (event) {
            if ($(this).next().find('>:first-child').length > 0) {
                event.preventDefault();
                $(this).next().find('>:first-child').slideToggle();
            }
        });
    }

    $('.previous-step-button', '#wishesform').unbind().click(function (event) {
        event.preventDefault();
        let currentStep = parseInt($(this).parents('.steps').attr('data-step'));
        let nextStep = currentStep - 1;

        $('.steps[data-step="' + currentStep + '"]').fadeOut(function () {
            $('.current-step').text(nextStep);
            $('h4', '.form-header').text($('.steps[data-step="' + nextStep + '"]').attr('data-heading'));
            $('.steps[data-step="' + nextStep + '"]').fadeIn();
        });
    });

    /**
     * Wishes form.
     */
    $('.next-step-button', '#wishesform').unbind().click(function (event) {
        if ($(this).is('#final-step')) {
            return;
        }

        event.preventDefault();

        var validationPassed = true;
        let currentStep = parseInt($(this).parents('.steps').attr('data-step'));
        let nextStep = currentStep + 1;
        var formData = '';

        $('input, textarea', '.steps[data-step="' + currentStep + '"]').not(':input[type=submit]').each(function (k, v) {
            if (!v.checkValidity()) {
                validationPassed = false;
                v.reportValidity();
            }

            formData += $(this).serialize() + '&';
        });

        if (validationPassed === false) {
            return;
        }

        $('.loader').css({'display': 'flex'});

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        $.ajax({
            type: 'POST',
            url: '/wishes/store',
            data: formData,
            success: function (data) {
                $('.steps[data-step="' + currentStep + '"]').fadeOut(function () {
                    $('.current-step').text(parseInt($('.current-step').text()) + 1);
                    $('h4', '.form-header').text($('.steps[data-step="' + nextStep + '"]').attr('data-heading'));
                    $('.steps[data-step="' + nextStep + '"]').fadeIn();
                });

                $('.loader').hide();
            },
            error: function (data) {
                var data = data.responseJSON;

                $.each(data, function (k, v) {
                    $("input[id='" + k + "']").css({'border': '1px solid red'});
                });

                $('.loader').hide();
            }
        });

    });

    $('#location_selection').change(function () {
        if ($(this).val() == 0) {
            $('.custom-location-wrapper').slideDown();
            return;
        }

        $('.custom-location-wrapper').slideUp();
    });

    $('.tab').click(function () {
        if ($(this).hasClass('selected')) {
            return false;
        }
        $('.tab').removeClass('selected');
        $(this).addClass('selected');

        $('.loader', '#flower-items-container').show();

        loadFlowers(
            $(this).attr('data-category')
        );

    });

    $('#left-control').click(function (event) {
        event.preventDefault();
        var leftPos = $('.container', '#flower-items-container').scrollLeft();
        $('.container', '#flower-items-container').animate({scrollLeft: leftPos - 257}, 800);
    });

    $('#right-control').click(function (event) {
        event.preventDefault();
        var leftPos = $('.container', '#flower-items-container').scrollLeft();
        $('.container', '#flower-items-container').animate({scrollLeft: leftPos + 257}, 800);
    });

    if ($('.cms-container').hasClass('flowersPage')) {
        loadFlowers(
            $('.tab', '.navigation').first().attr('data-category')
        );
    }

    setInterval(function () {
        $('blockquote:first-child', '.quote').fadeOut(500, function () {
            $(this).next('blockquote').fadeIn(1000).end().appendTo('.quote');
        });
    }, 5000);

    stickyMenu();

});

function loadFlowers(category) {
    let ids = [];

    $('.item', '.flowers-selected').each(function (k, v) {
        ids.push(parseInt($(v).attr('data-id')));
    });

    $.ajax({
        type: 'GET',
        url: '/flowers/category/' + category,
        success: function (data) {
            $('.container', '#flower-items-container').fadeOut(function () {

                $('#total-amount-flowers').html('').promise().done(function () {
                    $('#total-amount-flowers').html(data.total + ' bloemen in totaal');
                });

                $('#flower-items-wrapper').html('').promise().done(function () {
                    $.each(data.flowers, function (k, v) {
                        let tabItem = $('#tab-item').html()
                            .replace('%%title%%', v.name)
                            .replace('%%image%%', v.image)
                            .replace('%%price%%', v.price)
                            .replace('%%id%%', v.id);

                        if (ids.includes(v.id) === true) {
                            tabItem = tabItem.replace('%%overlay%%', 'display: block');
                        } else {
                            tabItem = tabItem.replace('%%overlay%%', '');
                        }

                        $('#flower-items-wrapper').append(tabItem);
                    });

                    $('.remove', '.overlay').click(function () {
                        let id = $(this).parent('.overlay').parent('.item').attr('data-id');
                        $('.item[data-id="' + id + '"]', '.flowers-selected').remove();
                        $(this).parent('.overlay').parent('.item').removeClass('selected');
                        $(this).parent('.overlay').hide();
                    });

                    $('.item', '#flower-items-wrapper').click(function (event) {
                        if ($(this).hasClass('selected') || $(event.target).hasClass('remove')) {
                            return false;
                        }

                        var self = this;

                        $(self).addClass('selected');
                        $(self).find('.overlay').show();

                        let flowerImage = $(self).find('img').attr('src');
                        let flowerId = $(self).attr('data-id');
                        let flowerPrice = $(self).find('.price').text();
                        let flowerTitle = $(self).find('.title').text();

                        let selectedItem = $('#selected-flower-item').html()
                            .replace('%%image%%', flowerImage)
                            .replace('%%title%%', flowerTitle)
                            .replace('%%price%%', flowerPrice)
                            .replace(/%%id%%/g, flowerId);

                        $('#no-flowers-selected').fadeOut(function () {

                            $('.flowers-selected').append(selectedItem);

                            $('.remove-item').click(function () {
                                var id = $(this).parent('.item').attr('data-id');
                                $('.item[data-id="' + id + '"]', '.flowers-selected').remove();
                                $('.item[data-id="' + id + '"]', '#flower-items-wrapper').removeClass('selected');
                                $('.item[data-id="' + id + '"] .overlay', '#flower-items-wrapper').hide();
                            });

                        });
                    });

                    $('.container', '#flower-items-container').fadeIn();
                });

                $('.loader', '#flower-items-container').hide();
            });
        }
    });
}

function stickyMenu() {

    window.onscroll = function () {
        myFunction()
    };

    var topbar = document.getElementById("top-bar");
    var logo = document.getElementById("kievit-logo");
    var header = document.getElementsByTagName('header')[0];
    var hhc = document.getElementById('homepage-heading-container');
    var sticky = topbar.offsetTop;

    function myFunction() {
        if (window.pageYOffset > sticky) {
            topbar.classList.add("sticky-top-bar");
            header.classList.add("sticky-header");
            logo.classList.add("sticky-logo");

            if (hhc) {
                hhc.classList.add('sticky-heading-container');
            }

        } else {
            topbar.classList.remove("sticky-top-bar");
            header.classList.remove("sticky-header");
            logo.classList.remove("sticky-logo");

            if (hhc) {
                hhc.classList.remove('sticky-heading-container')
            }
        }
    }
}